import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios  from 'axios'
Vue.config.productionTip = false;

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

import VueWechatTitle from 'vue-wechat-title';
Vue.use(VueWechatTitle)

import './assets/css/iconfont.css'


Vue.prototype.$axios = axios;

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if(token) {
    if(to.path == '/login') {
      next({
        path:'/'
      })
    }
    next()
  }else{
    if(to.path != '/login'){
      next({
        path:'/login'
      })
    }
    next()
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
