<template>
  <div class="tabbar">
    <van-tabbar v-model="active" active-color="red" inactive-color="#000">
      <van-tabbar-item to="/home" icon="home-o">我的工作</van-tabbar-item>
      <van-tabbar-item to="/addCourse">
        <div class="plugs">
          <van-icon class="plus-icon" name="plus" />
        </div>
      </van-tabbar-item>
      <van-tabbar-item to="/person" icon="user-o">个人中心</van-tabbar-item>
    </van-tabbar>
    <div style="height: 60px"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    }
  }
};
</script>

<style lang="scss" scoped>
.plugs {
  background-color: #E32322;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  .plus-icon {
    font-size: 25px;
    color: #FFFFFF;
  }
}
</style>
