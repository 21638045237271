import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/components/index';

Vue.use(VueRouter)

const routes = [
  {
    path:"/login",
    component: () => import("@/views/login")
  },
  {
    path:'/',
    redirect:'/home',
    component:Index,
    children: [
      {
        path:"/home",
        component: () => import("@/views/Home"),
        meta:{
          title:"我的工作"
        },
      },
      {
        path:"/course-detail",
        name:"course-detail",
        meta:{
          title:"报事详情"
        },
        component: () => import("@/views/course-detail")
      },
      {
        path:"/editPwd",
        meta:{
          title:"修改密码"
        },
        component: () => import("@/views/editpassword")
      },
      {
        path:"/person",
        name:"person",
        meta:{
          title:"我的资料"
        },
        component: () => import("@/views/person")
      },
      {
        path:"/addCourse",
        name:"addCourse",
        meta:{
          title:"报事"
        },
        component: () => import("@/views/addCourse")
      },
      {
        path:"/map",
        name:"map",
        meta:{
          title:"地图"
        },
        component: () => import("@/views/txMap")
      }
    ]
  }
]

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this,location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
