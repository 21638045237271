<template>
  <div class="index">
<!--    <my-header></my-header>-->
    <router-view  class="content" />
    <tabbar></tabbar>
  </div>
</template>

<script>
import MyHeader from "./MyHeader.vue";
import Tabbar from './tabbar.vue'
export default {
  components: {
    MyHeader,
    Tabbar
  },
};
</script>

<style lang="scss" scoped>

.index{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  .content{
    flex: 1;
    overflow: auto;
  }
}

</style>
