<template>
  <div class="header">
    <div class="header-content">
      <span>{{ title }}</span>
      <van-icon @click="$router.push('/home')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MyHeader",
  computed: {
    title() {
      return this.$route.meta.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-content {
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 15px;
  background-color: #E32322;
}

</style>
